import { ActionResolutionReason } from '@/features/insights/types/insights';

export default {
  key: 'es',
  strings: {
    'uptake-component-library': {
      pagination: {
        viewing: 'Viendo {current} de {total} Resultados',
        firstPage: 'Primera página',
        lastPage: 'Última página',        
      },
      userProfile: {
        userProfile: 'Perfil de Usuario',
        manageAccount: 'Administrar Cuenta',
        logOut: 'Cerrar Sesión',
      },
      multiselect: {
        cancel: 'Cancelar',
        go: 'Ir',
        'select-all': 'Seleccionar Todo / Deseleccionar Todo',
      },
      errorMessage: {
        dataNotFound: 'Datos No Encontrados',
      },
      search: {
        buttonLabel: 'Buscar',
      },
    },
    terms: {
      assets: 'Activos',
      insights: 'Hallazgos',
      insight: 'Hallazgo',
      cases: 'Casos',
      noResultsFound: 'Sin resultados',
      acceptInsight: 'Acceptar Hallazgo',
      disregardInsight: 'Ignorar el Hallazgo',
      acceptInsightReason: 'Por favor, confirma que deseas aceptar este hallazgo. Ingresa notas a continuación (opcional).', 
      disregardInsightReason: 'Motivo para descartar el hallazgo',
      insightDuplicate: 'El hallazgo es un duplicado',
      actionTakenInsight: 'Se tomó una acción sobre este hallazgo.',
      faultsTimeline: 'Faults Timeline',
      insightsTab: 'Hallazgos',


    },
    header: {
      search: {
        placeholder: 'ID del Activo para el Visor de Activos',
      },
      'upgrade-message': {
        title: 'Uptake',
        content:
          'proporciona a las organizaciones intensivas en activos soporte de decisión rápidamente configurable y estrategias de mantenimiento preventivo para optimizar las operaciones y el gasto en mantenimiento en todos los activos en función de costos, criticidad y riesgos al menor costo total de propiedad (TCO). Diferenciadores:',
        differentiators: [
          'Biblioteca de Estrategia de Activos con Algoritmos de Confiabilidad ACE',
          'Modelo de datos que permite analizar datos en múltiples sistemas/sitios',
          'Análisis de Riesgo con motores de ciencia de datos que calculan la probabilidad y consecuencia de fallos',
          'Widgets analíticos preconstruidos para ayudar a analizar tus datos como costos, valores atípicos, tiempo medio entre fallos',
          'Cumplimiento con ISO 14224',
          'Capacidad probada para generar retorno de inversión en menos de 60 días',
          'Retorno promedio de inversión de 10x',
        ],
        'learn-more': {
          message: '¿Te gustaría saber más?',
          demo: 'Agendar una Demostración Gratuita',
          info: 'Más Información',
        },
      },
    },
    riskExplorer: {
      pageTitle: 'EXPLORADOR DE RIESGOS',
      'units(s)': 'Unidad(es)',
      vehicleFamily: 'Familia de Vehículos',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'N° de Serie',
      topTen: 'PRIMEROS 10',
      vehiclesAtRisk: 'VEHÍCULOS EN RIESGO',
      risksByAsset: 'RIESGOS POR ACTIVO',
      riskByAssetDescription: 'Selecciona un activo a continuación para ver los detalles de su riesgo.',
      userSavedFilters: 'FILTROS GUARDADOS',
      assetRiskFactors: 'FACTORES DE RIESGO DEL ACTIVO',
      riskSummary: 'Resumen General de Riesgos',
      readiness: 'DISPONIBILIDAD',
      overall: 'general',
      vehicles: 'vehículos',
      top: 'top',
      current: 'Actual',
      actioned: 'accionado',
      insight: 'hallazgo',
      riskFactor: 'Factores de Riesgo',
      chartDescription:
        'El gráfico a continuación desglosa todos los vehículos en riesgo según su nivel de riesgo actual. Haz clic en un segmento del gráfico para ver el porcentaje de vehículos en riesgo en ese nivel.',
      allVehiclesModal: {
        title: 'Actual',
        vehiclesAtRiskTitle: 'Vehículos Actuales en Riesgo',
      },
      buttons: {
        allVehicles: 'Ver Todos los Vehículos',
      },
    },
    topInsights: {
      pageTitle: 'ACTIVOS + HALLAZGOS + CASOS',
    },
    assetInsightsSummary: {
      pageTitle: 'RESUMEN DE ACTIVOS/HALLAZGOS',
    },
    topInsightsFed: {
      pageTitle: 'ACTIVOS + HALLAZGOS',
    },
    userManagement: {
      confirmDeleteUser: '¿Deseas eliminar este usuario?',
      pageTitle: 'Gestión de Usuarios',
      LastName: 'Apellido',
      FirstName: 'Nombre',
      EmailAddress: 'Correo Electrónico',
      PhoneNumber: 'Número de Teléfono',
      Role: 'Rol',
      Unit: 'Unidad',
      UserStatus: 'Estado del Usuario',
      addUser: 'AGREGAR USUARIO',
      updateUser: 'ACTUALIZAR USUARIO',
      userName: 'NOMBRE DE USUARIO',
      email: 'CORREO ELECTRÓNICO',
      accessLevel: 'NIVEL DE ACCESO',
      selectLevel: 'Seleccionar Nivel de Acceso del Usuario',
      validation: 'Por favor, completa todos los campos requeridos con datos válidos',
      buttons: {
        resetForm: 'Restablecer Formulario',
        cancel: 'CANCELAR',
        addNewUser: 'Agregar Nuevo Usuario',
        updateUser: 'Actualizar Usuario',
        submit: 'Confirmar',
      },
      confirmation: {
        addUserTitle: '¿Deseas agregar este usuario?',
        updateUserTitle: '¿Deseas actualizar este usuario?',
        addUser: 'Por favor confirma que deseas agregar al usuario {name}.',
        updateUser: 'Por favor confirma que deseas actualizar al usuario {name}.',
      },
    },
    filtersModal: {
      pageTitle: 'GESTIÓN DE FILTROS',
      sectionOne: 'PASO 1 FILTROS',
      sectionTwo: 'PASO 2 FILTROS',
      MEF: 'MEF(s)',
      MSC: 'MSC(s)',
      REGIMENT: 'REGIMIENTO(s)',
      BN: 'BN(s)',
      AACUIC: 'AAC/UIC(s)',
      VEHICLEFAMILY: 'FAMILIA DE VEHÍCULOS',
      TAMCN: 'TAMCN(s) / NOMENCLATURA(s)',
      SERIAL: 'N° DE SERIE',
    },
    userProfile: {
      userProfile: 'Perfil de Usuario',
      manageAccount: 'Administrar Cuenta',
      logOut: 'Cerrar Sesión',
      logoutModal: {
        title: 'Has cerrado sesión en tu sesión anterior.',
        content: 'Por favor, vuelve a iniciar sesión para comenzar una nueva sesión.',
      },
      userData: {
        userName: 'Nombre de usuario: ',
        tenantCode: 'Código de inquilino: ',
        email: 'Correo electrónico: ',
        impersonator: 'Suplantador: ',
      },
    },
    RiskByAsset: {
      title: 'RIESGOS POR ACTIVO',
      description: 'Selecciona un activo a continuación para ver sus detalles de riesgo.',
      riskPercentage: 'Riesgo%',
      vehicleNumbers: 'Números de Vehículos',
    },
    VehiclesAtRisk: {
      riskPercentage: 'Riesgo%',
      vehicleNumber: 'Número de Vehículo',
      riskSummary: 'Resumen de Riesgo',
      vehicleReadiness: 'Disponibilidad del Vehículo',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Número de Serie(s)',
      missionReadyVehicles: 'Vehículos Listos para Misión',
      vehiclesAtRisk: 'Vehículos en Riesgo:',
    },
    OverViewByVehicleRisk: {
      Title: 'Resumen',
      description: 'Selecciona un activo a continuación para ver sus detalles de riesgo.',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Número de Serie(s)',
      riskPercentage: 'Riesgo%',
      NumberChangePercentage: 'Porcentaje de Cambio Numérico',
      DateChangePercentage: 'Porcentaje de Cambio de Fecha',
    },
    Methodology: {
      pageTitle: 'Metodología',
      pageSubTitle: 'detrás de las métricas',
      Description:
        'El puntaje de riesgo es un cálculo que representa la gravedad general de la salud de cada vehículo en función de fallas previstas, comportamiento operativo y antigüedad de las piezas. Un puntaje de alto riesgo indica un aumento en la probabilidad de problemas que una acción de mantenimiento o suministro podría resolver. Un puntaje de bajo riesgo indica un vehículo en buen estado, listo para misiones.',
    },
    Error: {
      DataNotFound: 'Datos No Encontrados',
    },
    methodology: {},
    riskExplorerFilters: {
      pageTitle: 'FILTROS DEL EXPLORADOR DE RIESGOS',
      'units(s)': 'Unidad(es)',
      vehicleFamily: 'Familia de Vehículos',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Número de Serie(s)',
      'nomenclature(s)': 'Nomenclatura(s)',
      'manufacturer-name(s)': 'Nombre del Fabricante(s)',
      'commission-date(s)': 'Fecha de Comisión(s)',
      'manufacturer-year(s)': 'Año de Fabricación(s)',
    },
    pagination: {
      viewing: 'Viendo {current} de {total} resultados',
      firstPage: 'Primera página',
      lastPage: 'Última página',      
      rowsPerPage: 'Filas por página',
      viewPager: 'Viendo {start}-{end} de {total}',
    },
    tableControls: {
      orderBy: 'Ordenar por',
      fields: {
        date: 'Fecha',
        author: 'Autor',
      },
    },
    login: {
      pageTitle: 'Iniciar Sesión',
      stepOne: 'Información del Usuario',
      stepTwo: 'Uso y Consentimiento',
      StepOneTitle: 'PASO 2: USO Y CONSENTIMIENTO',
      StepOneFormText: '',
      checkboxAcknowledgement:
        'Al marcar esta casilla, reconozco que he leído y acepto los términos de Uso y Consentimiento mencionados anteriormente.',
      copyrightText: 'Derechos de Autor {year} Uptake Technologies, Inc.',
      privacyNotice: 'Aviso de Privacidad',
    },
    caseActions: {
      createCase: 'Crear Caso',
      selectCase: 'Seleccionar un Caso',
      title: 'Título del Caso',
      description: 'Descripción',
      addNewCaseButton: 'Agregar Nuevo Caso',
      cancel: 'Cancelar',
      confirmation: '¿QUIERES CREAR?',
      information: '* Esta descripción se agregará a todos los casos seleccionados.',
    },
    addTocCase: {
      caseAddedTitle: 'Hallazgo(s) agregado(s) al Caso',
      caseAddedMessage: '{length} Hallazgo(s) agregado(s) al Caso.',
      caseFailedToAdd: 'No se agregaron Hallazgo(s)',
      caseFailedToAddMessage: '{length} Hallazgo(s) no se pudo(n) agregar al Caso.',
      caseToInsight: 'Agregar Hallazgo al Caso',
      caseToInsights: 'Agregar Hallazgo al Caso',
    },
    closeCase: {
      title: 'Cerrar Caso',
      confirmation: '¿Cómo te gustaría resolver los hallazgo asociados con este caso?',
    },
    uavFleet: {
      breadcrumb: {
        title: 'Visor de Activos',
      },
      downloadPdf: {
        description: 'Mientras monitoreabas tu equipo, Uptake generó el siguiente hallazgo:',
        downloadButton: 'Descargar Hallazgo',
        assetDetails: 'Detalles del Activo',
        insightDetails: 'Detalles del Hallazgo',
        vin: 'VIN',
        model: 'Modelo',
        manufacturer: 'Fabricante',
        modelYear: 'Año del Modelo',
        assetInsight: 'Hallazgo del Activo',
        supportingEvidence: 'Evidencia de Soporte',
        recommendedActions: 'Acciones Recomendadas',
        insightHiddenByAdmin: 'Hallazgo Oculto por el Administrador',
      },
      assetNumber: 'Activo #',
      asset: 'Activo',
      riskLevel: 'Puntuación de Riesgo',
      details: 'DETALLES',
      recentHistory: 'HISTORIAL RECIENTE',
      fleetOverview: 'RESUMEN',
      current: 'actual',
      activeInsights: 'Hallazgos Activos',
      recent: 'reciente',
      insight: 'HALLAZGOS',
      cases: 'CASOS',
      users: 'USUARIOS',
      connectors: 'CONECTORES',
      // viewInsights: 'insights',
      viewInsights: 'Hallazgos',
      workOrders: 'ÓRDENES DE TRABAJO',
      openCases: 'Casos Abiertos',
      viewWorkOrders: 'Órdenes de Trabajo',
      top: 'top',
      riskFactor: 'Factores de Riesgo',
      stillActiveTooltip:
        'Un ✔️ significa que los datos recientes indican que este problema probablemente ocurra. Una celda vacía significa que los datos han vuelto a un comportamiento operativo normal.',
      additionalAnalysis: 'ANÁLISIS ADICIONAL',
      recommendedAction: 'Acción Recomendada',
      summary: 'Resumen',
      faults: 'Eventos en los últimos',
      insightDetail: {
        actioned: 'Accionado',
        insights: 'HALLAZGOS',
        overview: 'RESUMEN',
        HiddenByAdmin: 'Oculto por el Administrador',
        insightSeverity: 'Guía',
        InsightEnd: 'Última Detección',
        InsightStart: 'Primera Detección',
        InsightType: 'Tipo',
        InsightStatus: 'Estado',
        heading: 'Detalles del Hallazgo',
        subsystem: 'Subsistema',
        ReadMore: 'Leer Más',
        ReadLess: 'Leer Menos',
        riskBySubsystem: 'Riesgo por SUBSISTEMA',
        engineOverheating: 'HALLAZGOS DE SOBRECALENTAMIENTO DEL MOTOR',
        insightStatusTransform: {
          ASSIGNED_TO_CASE: 'Asignado a Caso',
          ACCEPTED: 'Aceptado',
          DISREGARDED: 'Descartado',
          NEW: 'En Espera de Revisión',
        },
        insightCategoryTransform: {
          WORK_ORDER: 'Orden de Trabajo',
          FLUID: 'Fluido',
          SENSOR: 'Sensor',
          FAULT: 'Falla',
        },
        supportingEvidence: 'Evidencia de Soporte',
        actionDetailsTitle: 'DETALLES DE LA ACCIÓN',
        actionDetailsNotes: 'Notas',
        assignedToCase: 'Asignado al caso',
        caseOpen: 'Abierto',
        actionDetails: {
          [ActionResolutionReason.ActionTaken]: 'Se encontró un problema y se tomó una acción.',
          [ActionResolutionReason.ActionOnHold]: 'Se encontró un problema y la acción se puso en espera.',
          [ActionResolutionReason.NoIssueFound]: 'No se encontró ningún problema.',
          [ActionResolutionReason.InconclusiveInspection]: 'La inspección fue inconclusa.',
          [ActionResolutionReason.Duplicate]: 'El hallazgo es un duplicado.',
          [ActionResolutionReason.Ignore]: 'Ignorar.',
          [ActionResolutionReason.Other]: '',
        },
      },
      insightsTable: {
        emptyTableMessage: 'No hay hallazgos asociados con este activo',
      },
      workOrderDetail: {
        assetId: 'ID del Activo',
        createdOn: 'Creado el',
        description: 'Descripción',
        notes: 'Notas',
        task: 'Tarea',
        tasks: {
          meterReading: 'Lectura del Medidor',
          meterReadingUnit: 'Unidad de Lectura del Medidor',
          totalCost: 'Costo Total',
          workCode: 'Código de Trabajo',
        },
        workCompletedDate: 'Fecha de Finalización del Trabajo',
        workOrders: 'Órdenes de Trabajo',
        workOrderDetails: 'Detalles de la Orden de Trabajo',
        workorderId: 'ID de la Orden de Trabajo',
      },
      caseDetail: {
        description: 'DESCRIPCIÓN',
        dateCreated: 'FECHA DE CREACIÓN',
        caseDetails: 'DETALLES DEL CASO',
        caseStatus: 'ESTADO DEL CASO',
        cases: 'CASOS',
        commentSection: 'Registro de Comentarios',
        preferredUsername: 'CREADO POR',
        closedUsername: 'CERRADO POR',
      },
      casesTable: {
        emptyTableMessage: 'No hay casos asociados con este activo',
      },
      risk: {
        zones: {
          veryLow: 'Muy Bajo',
          low: 'Bajo',
          medium: 'Medio',
          high: 'Alto',
          veryHigh: 'Muy Alto',
          unknown: 'Desconocido',
        },
      },
      workOrdersTable: {
        emptyTableMessage: 'No hay órdenes de trabajo asociadas con este activo',
      },
      recentInsightHistory: {
        title: 'HALLAZGOS RECIENTES',
        titleTooltip: '30 Días Desde la Última Detección',
        insightName: 'Nombre del Hallazgo',
        stillActive: 'Aún Activo',
        subSystem: 'Subsistema',
        firstDetected: 'Primera Detección',
        lastDetected: 'Última Detección',
        noInsights: 'No hay hallazgos recientes en los últimos 30 días',
        viewAllInSights: 'Ver Todos los Hallazgos',
        details: 'Detalles',
        noData: 'SIN DATOS',
        noFaults: 'No se detectaron fallas en los últimos {daysCount} días',
        realTime: 'CASI EN TIEMPO REAL',
        summary: 'RESUMEN DIARIO',
      },
    },
    connector: {
      confirmDeleteConnector: '¿Quieres eliminar este conector?',
      connectors: 'CONECTORES:',
      selectConnector: 'Seleccionar Conector',
      connectorName: 'NOMBRE DEL CONECTOR',
      credentialsTitle: 'INGRESAR CREDENCIALES',
      dataLakeAutomation: 'Automatización de DataLake',
      invalidConnectorName: 'Nombre de conector no válido',
      testResult: 'RESULTADO DE LA PRUEBA',
      credentialsSaved: 'CREDENCIALES GUARDADAS',
      connectorType: 'CONECTOR: {name}',
      addConnector: 'AGREGAR CONECTOR',
      updateConnector: 'ACTUALIZAR CONECTOR',
    },
    alert: {
      errorTitle: 'Los datos no se cargaron',
      errorMessage:
        'Algo salió mal. Por favor, actualiza la página e intenta nuevamente. Si el error persiste, comunícate con',
      mail: "gov-support{'@'}uptake.com",
      mailFleet: "help{'@'}uptake.com",
    },
    buttons: {
      search: 'Buscar',
    },
    userSavedFilters: {
      header: 'Administrar Filtros Guardados',
      editFilterTitle: 'Editar Filtro Guardado',
      createFilterTitle: 'Crear Nuevo Filtro',
      newFilterName: 'Nuevo Nombre de Filtro',
      allAssets: 'Todos los Activos',
      closeFlyout: 'Cerrar Panel',
      count: '{total} Activos cumplen con los criterios seleccionados',
      organizationalFilters: 'Filtros Organizacionales',
      assetFilters: 'Filtros de Activos',
      step: 'Paso {step} de 2 Filtros',
      currentStep: '{currentStep}',
      buttons: {
        close: 'Cerrar',
        cancel: 'Cancelar',
        save: 'Guardar',
        saveAs: 'Guardar Como',
        apply: 'Aplicar',
        next: 'Siguiente',
        reset: 'Restablecer Todos los Filtros',
      },
      confirmationMessages: {
        invalidText: {
          title: ' No hay filtros inválidos |  Filtro Guardado Inválido |   Filtros Guardados Inválidos',
          message:
            'No hay filtros inválidos |  Hay un filtro guardado que ya no es válido debido a tu configuración de permisos actual.|  Hay filtros guardados que ya no son válidos debido a tu configuración de permisos actual.',
        },
        createCase: {
          title: '¿Quieres crear "1 nuevo caso"?',
          message:
            'Por favor, confirma que deseas crear "1 Nuevo Caso" a partir de los "{insightCount} Hallazgos Seleccionados". Haz clic en la tarjeta de Casos para ver los casos de estos hallazgos.',
        },
        createXCase: {
          title: '¿Quieres crear "{insightCount} nuevos casos"?',
          message:
            'Por favor, confirma que deseas crear "{insightCount} Nuevos Casos" a partir de los "{insightCount} Hallazgos Seleccionados". Haz clic en la tarjeta de Casos para ver los casos de estos hallazgos.',
        },
        buttons: {
          confirm: 'Confirmar',
          save: 'Guardar',
          cancel: 'Cancelar',
          deleteAll: 'No hay filtros inválidos |  Eliminar Filtro Inválido |   Eliminar Todos los Filtros Inválidos',
          alt: 'Cerrar ventana emergente',
        },
      },
      saved: {
        title: 'Filtros Guardados',
        buttons: {
          default: 'Predeterminado',
          delete: 'Eliminar',
          edit: 'Editar',
          copy: 'Copiar',
        },
      },
      flyout: {
        title: 'Filtros Activos',
        tooltip: 'Nota: Todos los datos se filtrarán según estos valores, a menos que se especifique lo contrario.',
        placeholder: 'Selecciona un Filtro',
      },
      createNewFilterInput: {
        placeholder: 'Filtro Sin Nombre',
      },
    },
    fleetUserSavedFilters: {
      region: 'Región(es)',
      terminals: 'Terminal(es)',
      assets: 'Activo(s)',
      modalTitle: 'FILTRAR ACTIVOS',
    },
    faultsTimeline: {
      title: 'LÍNEA DE TIEMPO DE FALLAS',
      by: 'por',
      subTitle: 'Eventos en los últimos 30 días',
      footerMessage: '*Haz clic en cualquier elemento del eje X o en la leyenda para activarlo/desactivarlo.',
      reset: 'Restablecer',
    },
    componentLib: {
      dstableStrings: {
        noContentDefault: 'Sin datos',
      },
    },
    commentSection: {
      addComment: 'Agregar un Comentario',
      add: 'AGREGAR',
    },
    adminTabs: {
      user: 'Administrar Usuarios',
      connector: 'Administrar Conectores',
      autoCaseCreate: 'Administrar Casos Automáticos',
      insight: 'Administrar Hallazgos',
      title: 'CONFIGURACIÓN DEL ADMINISTRADOR',
    },
    manageLegend: {
      title: 'Administrar Leyenda de Hallazgos',
      instructionMessage: 'Puedes ajustar el valor de la leyenda para cada campo de severidad.',
      validationMessage: {
        partOne: 'Evita caracteres especiales ',
        specialChar: '{special} ',
        partTwo: 'o puntuación y ten en cuenta el límite de ',
        charCount: '30',
        partThree: ' caracteres.',
      },
    },
    manageInsights: {
      title: 'ADMINISTRAR HALLAZGOS',
      onLabel: 'Encendido',
      offLabel: 'Apagado',
      saveButtonLabel: 'Guardar',
      filterModalTitle: 'Filtro de Configuración de Casos de Hallazgos',
      filterModalConfigLabel: 'APLICAR',
      inputSearchPlaceholderText: 'Buscar por Nombre o Subsistema',
      autoOpenCaseTitleTemplate: 'Actualizar Configuración de Caso Automático',
      autoOpenCaseDescriptionTemplate: 'Actualizar Configuración de Caso Automático',
      saveLegendMessage: {
        title: 'Guardar Leyenda',
        message: 'Los cambios en la leyenda se guardaron correctamente',
      },
      updateMessage: {
        title: 'Configuración de Apertura Automática de Caso',
        message: 'La configuración de apertura automática de casos se actualizó correctamente',
      },
      createMessage: {
        title: 'Administrar Configuración de Hallazgos',
        message: 'La configuración se guardó correctamente',
      },
      errorMessage: {
        title: 'Error',
        message: 'La operación falló debido a un error interno del servidor',
      },
      baseLegends: {
        CATASTROPHIC: 'Acción Inmediata',
        CRITICAL: 'Acción en la Primera Oportunidad',
        MARGINAL: 'Acción en la Próxima Visita',
        NEGLIGIBLE: 'Próximo Mantenimiento Preventivo',
      },
      filter: {
        title: 'Todos los Filtros',
        instructionMessage: 'Selecciona los filtros que deseas aplicar',
      },
    },
    createCase: {
      title: 'Selecciona una opción a continuación para crear caso(s)',
      subTitle: '{insightCount} hallazgo(s) seleccionado(s)',
      optionOne: 'Crear {insightCount} Nuevos Casos para {insightCount} Hallazgo(s) Seleccionado(s)',
      optionTwo: 'Crear 1 Nuevo Caso para {insightCount} Hallazgo(s) Seleccionado(s)',
    },
    notificationManagement: {
      alert: 'No hay una dirección de correo electrónico asociada con este usuario. Por favor, comunícate con',
      support: 'para soporte.',
      title: 'GESTIÓN DE NOTIFICACIONES',
      alertTitle: 'DIRECCIÓN DE CORREO ELECTRÓNICO FALTANTE',
      loading: 'Enviando y procesando datos...',
      confirmation: '¿Estás seguro de que quieres salir de la gestión de notificaciones sin guardar?',
      confirmTitle: 'CONFIRMAR SALIDA',
      confirmExitButtonTitle: 'Salir sin guardar',
      saveNofificationsButton: 'Guardar Notificaciones',
      buttons: {
        save: 'Guardar',
      },
    },
    quickSight: {
      error:
        'Parece que hay un problema al cargar este panel. Intenta recargar la página y, si el problema persiste, contacta con el soporte.',
      reload: 'RECARGAR PÁGINA',
    },
    dsModal: {
      buttons: {
        close: 'Cerrar',
        cancel: 'Cancelar',
        download: 'DESCARGAR (.CSV)',
      },
      download: {
        progress: 'Descarga en progreso... ',
        warning:
          'Por favor, no navegues fuera de esta página o la descarga no se completará. Esto puede tardar unos minutos.',
        message:
          'La descarga incluirá todos los resultados de esta tabla. El tiempo de descarga variará según el número de resultados. Para reducir el tiempo de descarga, considera reducir los resultados con un filtro guardado.',
      },
    },
    aicCards: {
      viewAllCases: 'Ver Todos los Casos',

      guidance: 'Recomendación',
      insightName: 'Nombre del Hallazgo',
      insightStatus: 'Estado del Hallazgo',
      insightState: 'Condición del Hallazgo',
      insightStart: 'Inicio del Hallazgo',
      insightsTitle: 'Hallazgos que Requieren Acción',
      insightsTooltip: '"Hallazgos que Requieren Acción": Hallazgos que aún no han sido accionados por ningún usuario.',
      casesTitle: 'Casos Abiertos',
      viewAllInsights: 'Ver Todos los Hallazgos',
      viewAllAssets: 'Ver Todos los Activos',
      viewActions: 'Ver Hallazgos que Requieren Acción',
      viewOpenCases: 'Ver Casos Abiertos',
      newCase: 'Nuevos Casos Agregados',
      insightStart: 'Inicio del Hallazgo',
      createCase: {
        confirmation:
          'Por favor confirma que deseas crear {records} nuevo(s) caso(s) a partir de los hallazgos seleccionados.',
        click: 'Haz clic en ',
        cases: 'Casos',
        card: ' para ver los casos de estos hallazgos.',
      },
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Confirmar',
      },
    },
    SiteSectionText: {
      OverviewDashboard: 'Explorador de Estrategia',
      RiskExplorer: 'Explorador de Riesgos',
      DataRecon: 'Reconciliación de Datos',
      Assets: 'Activos',
      Insights: 'Hallazgos',
      Cases: 'Casos',
      GroupManagement: 'Gestión de Grupos',
      AssetsInsightsCases: 'ACTIVOS + HALLAZGOS + CASOS',
      AssetsInsights: 'ACTIVOS + HALLAZGOS',
      WorkOrderAnalytics: 'Análisis de Órdenes de Trabajo',
      Reporting: 'Reportes',
      CalculationDetails: 'Detalles de Cálculos',
      FleetInsightAssessment: 'Evaluación de Hallazgos de Flota',
      AssetInsightsSummary: 'Beta - Resumen de Activos y Hallazgos',
      RepeatedRepairs: 'Reparaciones Repetidas',
      RemoteDiagnostic: 'Beta - Diagnóstico Remoto'
    },
    tableLabels: {
      ['Access Level']: 'Nivel de Acceso',
      ['Action Earliest Opportunity']: 'Tomar acción en la primera oportunidad',
      ['Action Immediately']: 'Tomar acción de inmediato',
      ['Action Next Visit']: 'Tomar acción en la próxima visita',
      ['Asset ID']: 'ID del Activo',
      ['Asset Number']: 'Número de Activo',
      ['Automatic Create Case']: 'Creación Automática de Caso',
      ['Case Title']: 'Título del Caso',
      ['Close By']: 'Cerrar Antes de',
      ['Connector']: 'Conector',
      ['Connector Name']: 'Nombre del Conector',
      ['Connector Type']: 'Tipo de Conector',
      ['Created By']: 'Creado Por',
      ['Created On']: 'Creado En',
      Criticality: 'Criticidad',
      ['Daily Summary']: 'Resumen Diario',
      ['Date of Last Action Taken']: 'Fecha de la Última Acción Tomada',
      Description: 'Descripción',
      Email: 'Correo Electrónico',
      ['Filtered Total']: 'Total Filtrado',
      ['First Name']: 'Nombre',
      Guidance: 'Recomendación',
      ['Hidden by Admin']: 'Oculto por el Administrador',
      Insight: 'Hallazgo',
      ['Insights Awaiting Review']: 'Hallazgos Pendiente de Revisión', 
      ['Insight Duration']: 'Duración del Hallazgo',
      ['Insight Name']: 'Nombre del Hallazgo',
      ['Insight Start']: 'Inicio del Hallazgo',
      ['Insight Status']: 'Estado del Hallazgo',
      ['Insight Summary']: 'Resumen del Hallazgo',
      ['Insight Title']: 'Título del Hallazgo',
      ['Insight Type']: 'Tipo de Hallazgo',
      ['Last Name']: 'Apellido',
      ['life Time Insight Count']: 'Cantidad de Hallazgos en el Tiempo de Vida',
      Make: 'Marca',
      Model: 'Modelo',
      ['Model Year']: 'Año del Modelo',
      ['Near Real Time']: 'Casi en Tiempo Real',
      ['Next Preventive Maintenance']: 'Próximo Mantenimiento Preventivo',
      Note: 'Nota',
      Notes: 'Notas',
      ['Notification Preference']: 'Preferencia de Notificación',
      ['Open Cases']: 'Casos Abiertos',
      preference: 'Preferencia',
      resolution: 'Resolución',
      ['Resolution Reason']: 'Motivo de la Resolución',
      ['Risk Score']: 'Puntaje de Riesgo',
      Severity: 'Severidad',
      Status: 'Estado',
      Subsystem: 'Subsistema',
      Title: 'Título',
      Type: 'Tipo',
      ['User Name']: 'Nombre de Usuario',
      Vin: 'Vin',
      VIN: 'Vin',
      vin: 'Vin',
      Visible: 'Visible',
      ['Work Description']: 'Descripción del Trabajo',
      ['Work Order ID']: 'ID de Orden de Trabajo'
    }
  },
};